import { MessageDescriptor } from 'react-intl'

import { OptionModel } from 'app/hooks/useOptions'
import type { AddressObject } from 'app/services/Geosuggest/Geosuggest.types'

import { isOfType } from './type.helpers'

export const transformIntlOptions = (
  options: OptionModel[],
  formatMessage: (intlObject: MessageDescriptor) => string,
  fieldName = 'label'
) =>
  options.map((el) => {
    const label = isOfType.string(el[fieldName])
      ? el[fieldName]
      : formatMessage(el[fieldName])

    return { ...el, [fieldName]: label }
  })

export const transformAddressObjectToString = (
  item?: Partial<ShippingAddresses>,
  fullCountry?: boolean
) => {
  const country = fullCountry ? item?.full_country_name : item?.country

  return [
    item?.premise_address,
    item?.subpremise_address,
    item?.address,
    item?.city,
    item?.zip_code,
    country
  ]
    .filter(Boolean)
    .join(', ')
}

export const transformAddressToSelectOption = <T>(
  address: T
): { label: string; value: T } => ({
  label: transformAddressObjectToString(address || {}),
  value: address
})

export const transformAddressToDTO = (address: Partial<AddressObject>) => {
  const streetAddress = !!address?.street_number
    ? `${address?.street_number} ${address?.street}`
    : address?.street
  const subBuildingAddress = !!address?.sub_building
    ? `${address?.sub_building}, ${streetAddress}`
    : streetAddress

  const addressString = !!address?.company_name
    ? `${address?.company_name}, ${subBuildingAddress}`
    : subBuildingAddress

  return {
    subpremise_address: address?.subpremise || null,
    premise_address: address?.premise || null,
    address: addressString,
    apartment: address?.street_number,
    city: address?.city,
    state: address?.region,
    zip_code: address?.postal_code,
    country: address?.country,
    company_name: address?.company_name,
    administrative_area: address?.state,
    sub_building: address?.sub_building,
    loqate_place_id: address?.loqate_place_id
  }
}

export const transformAddressToGeosuggestValue = (
  address?: Partial<AddressObject>
) => {
  if (!address) return

  return {
    label: transformAddressObjectToString(address, true),
    description: transformAddressObjectToString(address, true),
    value: address
  }
}

export const transformPartNumber = (v) => (v ? String(v).toUpperCase() : v)

export const transformAcceptedFileFormatToImageFormat = (
  accept: string | string[]
) => {
  const checkWord = 'image/'

  if (isOfType.array(accept)) {
    return accept
      .filter((el) => el.includes(checkWord))
      .map((el) => el.replace(checkWord, ''))
  }

  return accept.replace(checkWord, '')
}

export const transformGenerationYearsToString = (
  generation?: ListingGenerationModel
) => {
  if (generation?.year_begin) {
    return `[${generation?.year_begin} - ${generation?.year_end || ''}]`
  }

  return ''
}
